.more-detalis {
  height: calc(100vh - (74.8px + 79px));

  .wrap-window {
    width: calc(100% - 40px);
    margin: 0 auto 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .element-scroll{
      flex-basis: 21%;
      padding: 50px 20px 10px;
      height: calc(100vh - (74.8px + 79px));
      position: relative;
      overflow: hidden;
      text-align: center;
      box-shadow: 0 10px 2px rgba(0, 0, 0, 0.14);
      background-color: #ffffff;
      transition-duration: 500ms;
      transition-property: background-color, box-shadow;
      &:hover{
        box-shadow: 0 10px 2px rgba(0, 0, 0, 0.14);
        background-color: rgba(2,47,93, .1);
      }
      .window-element{
        width: 100%;
        margin-bottom: 10px;
        padding-top: 20px;

        a {
          width: 100%;
          height: 400px;
          position: relative;
          display: block;
          &::before{
            content: '';
            width: 50%;
            height: 30%;
            background: #022f5d;
            position: absolute;
            top: -5%;
            right: -5%;
          }
          &:hover{
            img {
              transform: scale(1.1);
            }
          }
          img {
            display: block;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            position: relative;
            z-index: 9;
            transition-duration: 500ms;
            transition-property: transform;
          }
        }
      }
      .title-grand{
        color: #022f5d;
        font-family: "Bebas Neue";
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 2.83px;
        margin-top: 23px;
        min-height: 70px;
      }
      .desk-advantages{
        font-size: 18px;
        height: 80px;
        padding: 10px 20px 20px;
        display: block;
      }
      .text-btn{
        width: 229px;
        height: 50px;
        background-color: #022f5d;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #ffffff;
        margin: 0 auto 20px;
        font-size: 27px;
        line-height: 27px;
        font-weight: normal;
        letter-spacing: 2.55px;
        transition-duration:  500ms;
        transition-property: background-color;
        margin-bottom: 36px;
        &:hover{
          background-color: darken(#022f5d, 15%);
        }
      }
    }
  }
}
@media(max-width: 1400px){
  .more-detalis {
    height: calc(100vh - (50.84px + 50px));

  }
  .more-detalis .wrap-window .element-scroll {
    height: calc(100vh - (50.8px + 50px));
  }

  .more-detalis .wrap-window .element-scroll{
    padding: 10px 15px ;
  }

  .more-detalis .wrap-window .element-scroll .title-grand{
    font-size: 24px;
    min-height: 50px;
    margin-bottom: 0;
    margin-top: 10px;
  }
  .more-detalis .wrap-window .element-scroll .desk-advantages{
    font-size: 16px;
    height: 83px;
  }
  .more-detalis .wrap-window .element-scroll .text-btn{
    width: 229px;
    height: 50px;
    font-size: 22px;
  }
  .more-detalis .wrap-window .element-scroll .window-element a{
    height: 306px;
    width: 95%;
    margin: 0 auto;
  }
  .more-detalis .wrap-window .element-scroll .window-element a:hover img {
  }
}
@media(max-width: 1199px){
  .more-detalis .wrap-window .element-scroll .desk-advantages{
    padding: 6px 5px 5px;
  }
}
@media(max-width: 1147px){
  .more-detalis .wrap-window .element-scroll .desk-advantages{
    height: 100px;
  }
}

@media(max-width: 1075px){
  .more-detalis {
    height: auto;
  }
  .more-detalis .wrap-window .element-scroll{
    height: auto;
  }
  .more-detalis .wrap-window .element-scroll .desk-advantages{
    min-height: 100px;
  }
  .more-detalis .wrap-window .element-scroll .window-element a {
    height: auto;
  }
}
@media(max-width: 1075px){
  .more-detalis .wrap-window .element-scroll{
    flex-basis: 100%;
  }
}
@media(max-width: 723px){
  .more-detalis .wrap-window .element-scroll .window-element a{
    height: 501px;
  }
}
@media(max-width: 579px){
  .more-detalis .wrap-window .element-scroll .window-element a{
    height: 301px;
  }
}
@media(max-width: 439px){
  .more-detalis .wrap-window .element-scroll .window-element a{
    height: 201px;
  }
}

