.product-six {
  background: #fff;
  position: relative;
  z-index: 9;
  padding: 90px 0 50px;
  .wrap-product{
    display: flex;
    justify-content: center;
    align-items: center;
    .box-product__img{
      flex-basis: 80%;
      position: relative;
      img {
        display: block;
        max-width: 100%;
        position: relative;
      }
      &::before{
        content: '';
        width: 20%;
        height: 80%;
        position: absolute;
        background: #022f5d;
        top: -40px;
        left: -40px;
      }
    }
    .box-product__text{
      flex-basis: 40%;
      padding: 0 40px;
      background-color: #ffffff;
      margin-left: -40px;
      position: relative;
      .box-text-scroll-about{
        height: 220px;
        position: relative;
        overflow: auto;
        margin-bottom: 50px;
        padding-right: 10px;
        &::-webkit-scrollbar-thumb {

          background-color: rgba(2, 47, 93, 0.8);
          border-radius: 25px;

        }

        &::-webkit-scrollbar-thumb:hover{
          background-color:#56999f;
          border-radius: 25px;

        }

        &::-webkit-resizer{
          background-repeat:no-repeat;
          width:5px;
          height: 10px;
          border-radius: 25px;

        }

        &::-webkit-scrollbar{
          width: 6px;
          height: 10px;
          border-radius: 25px;
        }
      }
    }
  }
}
@media(max-width: 1400px){
  .product-six .wrap-product .box-product__text{
    flex-basis: 50%;
  }
  .product-six {
    .wrap-product {
      .box-product__text {
        .title-product {
          font-size: 24px;
        }
        .box-text-scroll-about {
          .desk-product {
            font-size: 16px;
          }
        }

      }
    }
  }
}
@media(max-width: 1110px){
  .product-six .wrap-product{
    flex-wrap: wrap;
  }
  .product-six {
    margin-top: -1px;
  }
  .product-six .wrap-product .box-product__text{
    flex-basis: 76%;
    margin-left: 0;
    padding: 0;
  }
  .product-six .wrap-product .box-product__text .box-text-scroll-about{
    height: auto;
  }


}
@media(max-width: 624px){
  .product-six {
    padding: 25px 0;
  }
  .product-six .wrap-product .box-product__img{
    flex-basis: 100%;
  }
  .product-six .wrap-product .box-product__text{
    flex-basis: 100%;
  }

}
