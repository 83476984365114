.book {
  margin: 10px 0 50px;
  position: relative;
  z-index: 9;
  .book-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .book-left{
      flex-basis: 70%;
      img {
        display: block;
        max-width: 100%;
      }
    }
    .book-right {
      flex-basis: 28%;
      .title-product {
        color: #022f5d;
        font-family: "Bebas Neue";
        font-size: 30px;
        font-weight: 700;
        letter-spacing: 2.83px;
        margin: 0 0 25px;
      }
      .text-book {
        color: #222324;
        font-family: 'Verdana Regular';

        font-size: 18px;
        font-weight: 300;

        margin: 0 0 18px;
      }
    }
  }
}
@media(max-width: 1400px){
  .book .book-wrap .book-right .title-product{
    font-size: 24px;
  }
  .book .book-wrap .book-right .text-book{
    font-size: 16px;
  }
}
@media(max-width: 900px){
  .book .book-wrap .book-left, .book .book-wrap .book-right{
    flex-basis: 100%;
    img {
      margin: 0 auto;
    }
  }
  .book{
    margin: 0;
  }
}
