.two-blocks{
  height: calc(100vh - (74.8px + 79px));
  display: flex;
  justify-content: center;
  align-items: center;
  .wrap-window{
    width: calc(100% - 40px);
    margin: 20px auto 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .two-blocks__element{
      flex-basis: 48%;
      .box-img{
        width: 70%;
        display: block;
        margin: 0 auto 20px;
        position: relative;
        &::before{
          content: '';
          width: 40%;
          height: 60%;
          background: #022f5d;
          top: -15px;
          right: -20px;
          position: absolute;
        }
        img {
          display: block;
          width: 100%;
          margin: 0 auto 20px;
          position: relative;
        }
      }
      .title-grand{
        text-align: center;
        margin-bottom: 20px;
        text-decoration: none;
        display: block;
      }
      .btn-two{
        width: 254px;
        height: 55px;
        background-color: #022f5d;
        display: flex;
        justify-content: center;
        align-items: center;
        transition-duration: 500ms;
        transition-property: background-color;
        margin: 0 auto 20px;
        color: #ffffff;
        font-family: "Bebas Neue";
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 2.55px;
        text-decoration: none;
        &:hover{
          background-color: darken(#022f5d, 15%);
        }
      }

    }
  }
}
@media(max-width: 1400px){
  .two-blocks {
    height: calc(100vh - (50.84px + 50px));

  }
  .two-blocks .wrap-window .two-blocks__element .box-img{
    width: 55%;
  }
  .two-blocks .wrap-window .two-blocks__element .title-grand{
    font-size: 24px;
  }
  .two-blocks .wrap-window .two-blocks__element .btn-two{
    width: 229px;
    height: 50px;
    font-size: 22px;
  }
}
@media(max-width: 1200px){
  .two-blocks {
    height: auto;
  }
  .two-blocks .wrap-window .two-blocks__element{
    flex-basis: 100%;
  }
}