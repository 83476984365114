.sertificat{
  margin: 100px 0;
  position: relative;
  .swiper-slide{

    a {
      width: 80%;
      text-decoration: none;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
  .swiper-button-next {
    right: 20px;
    outline: none;
  }
  .swiper-button-prev {
    left: 20px;
    outline: none;

  }

}
.box-product-width{
  width: calc(623px - 80px)!important;

}
@media(max-width: 479px){
  .box-product-width {
    width: calc(100% - 80px) !important;
  }
}
@media(max-width: 414px){
  .box-product-width {
    width: calc(100% - 30px)!important;
    padding: 20px 15px!important;
  }
}
.box-product-widthTwo{
  width: 37% !important;
}
@media(max-width: 479px){
  .box-product-widthTwo {
    width: calc(100% - 80px) !important;
  }
}
@media(max-width: 414px){
  .box-product-widthTwo {
    width: calc(100% - 30px)!important;
    padding: 20px 15px!important;
  }
}

.fancybox-container{
  z-index: 9999999999999999!important;
}
