
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,300i,400,400i,700,700i,900,900i&display=swap&subset=latin-ext');
//font-family: n


@font-face {
  font-family: 'Bebas Neue';
  src: url('../fonts/BebasNeueBold.eot');
  src: url('../fonts/BebasNeueBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BebasNeueBold.woff2') format('woff2'),
  url('../fonts/BebasNeueBold.woff') format('woff'),
  url('../fonts/BebasNeueBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Bebas Neue Book';
  src: url('../fonts/BebasNeueBook.eot');
  src: url('../fonts/BebasNeueBook.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BebasNeueBook.woff2') format('woff2'),
  url('../fonts/BebasNeueBook.woff') format('woff'),
  url('../fonts/BebasNeueBook.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Bebas Neue';
  src: url('../fonts/BebasNeueLight.eot');
  src: url('../fonts/BebasNeueLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BebasNeueLight.woff2') format('woff2'),
  url('../fonts/BebasNeueLight.woff') format('woff'),
  url('../fonts/BebasNeueLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Bebas Neue';
  src: url('../fonts/BebasNeue-Thin.eot');
  src: url('../fonts/BebasNeue-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BebasNeue-Thin.woff2') format('woff2'),
  url('../fonts/BebasNeue-Thin.woff') format('woff'),
  url('../fonts/BebasNeue-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Bebas Neue';
  src: url('../fonts/BebasNeueRegular.eot');
  src: url('../fonts/BebasNeueRegular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BebasNeueRegular.woff2') format('woff2'),
  url('../fonts/BebasNeueRegular.woff') format('woff'),
  url('../fonts/BebasNeueRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arial MT';
  src: url('../fonts/ArialMT.eot');
  src: url('../fonts/ArialMT.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ArialMT.woff2') format('woff2'),
  url('../fonts/ArialMT.woff') format('woff'),
  url('../fonts/ArialMT.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Arial - Bold MT";
  src: url('../fonts/Arial-BoldMT.eot');
  src: url('../fonts/Arial-BoldMT.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Arial-BoldMT.woff2') format('woff2'),
  url('../fonts/Arial-BoldMT.woff') format('woff'),
  url('../fonts/Arial-BoldMT.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Verdana Regular';
  src: url('../fonts/Verdana.eot');
  src: local('Verdana'),
  url('../fonts/Verdana.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Verdana.woff') format('woff'),
  url('../fonts/Verdana.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Verdana Bold';
  src: url('../fonts/Verdana-Bold.eot');
  src: local('Verdana Bold'), local('Verdana-Bold'),
  url('../fonts/Verdana-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Verdana-Bold.woff') format('woff'),
  url('../fonts/Verdana-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@import url('https://fonts.googleapis.com/css?family=Comfortaa:400,500,600&display=swap&subset=cyrillic');