.price{
  height: calc(100vh - (74.8px + 79px));

  position: relative;
  overflow: auto;
  .box__price--flex{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .price--element {
      flex-basis: calc(387px - (25px + 14px));
      padding: 21px 25px 0 14px;
      border: 1px solid #d0d0d0;
      background-color: #ffffff;
      margin-bottom: 33px;
      transition-duration: 500ms;
      transition-property: background-color, box-shadow;
      &:hover{
        box-shadow: 0 10px 2px rgba(0, 0, 0, 0.14);
        background-color: rgba(2,47,93, .1);
      }
      .price-img {
        width: 100%;
        position: relative;
        margin-bottom: 43px;
        &::before{
          content: '';
          position: absolute;
          width: 188px;
          height: 226px;
          background-color: #022f5d;
          top: -10px;
          right: -12px;
        }
        img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
          position: relative;
        }
      }
      .title-grand{
        text-align: center;
        margin-bottom: 21px;
      }
      .box-text{
        .text-list{
          color: #222324;
          font-family: 'Verdana Regular';
          font-size: 18px;
          font-weight: 400;
          margin: 0 0 10px;
          position: relative;
          padding-left: 20px;
          &::before{
            content: '';
            width: 10px;
            height: 10px;
            background-color: #022f5d;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
      .text-price{
        color: #022f5d;
        font-family: "Bebas Neue";
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 2.83px;
        text-align: center;
        margin: 20px 0;
      }
      .btn-price{
        width: 229px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition-duration: 500ms;
        transition-property: background-color, color, border-color;
        margin: 0 auto 20px;
        background-color: #022f5d;
        color: #fff;
        border: 1px solid #022f5d;

        font-family: "Bebas Neue";
        font-size: 27px;
        line-height: 27px;
        font-weight: normal;

        letter-spacing: 2.55px;
        text-decoration: none;
        &:hover{
          background: darken(#022f5d, 15%);
        }
      }
    }
  }
}
@media(max-width: 1400px){
  .price {
    height: calc(100vh - (100px));
    .title-h2 {
      font-size: 24px;
    }

  }

  .price .box__price--flex .price--element{
    flex-basis: calc(331px - (25px + 14px));
  }
  .price .box__price--flex .price--element .title-grand{
    margin-bottom: 12px;
    font-size: 24px;
  }
  .price .box__price--flex .price--element .box-text .text-list{
    font-size: 16px;
  }
  .price .box__price--flex .price--element .text-price{
    font-size: 24px;
  }
  .price .box__price--flex .price--element .price-img{
    margin-bottom: 20px;
  }
  .price .box__price--flex .price--element .box-text .text-list{
    &::before{
      width: 7px;
      height: 7px;
    }
  }
  .price .box__price--flex .price--element .btn-price{
    font-size: 22px;
  }


}
@media(max-width: 779px){
  .title-h2{
    font-size: 24px;
  }
  .price .box__price--flex .price--element .btn-price{
    width: 150px;
    height: 40px;
    font-size: 16px;
  }
  .price .box__price--flex .price--element .title-grand{
    font-size: 24px;

  }
    .price .box__price--flex .price--element .box-text .text-list {
    font-size: 14px;

  }
    .price .box__price--flex .price--element .text-price{
    font-size: 24px;
  }
  .price .box__price--flex .price--element{
    margin: 20px auto;
  }
}
@media(max-width: 414px){
  .price .box__price--flex .price--element{
    flex-basis: calc(100% - (25px + 14px));
  }

}