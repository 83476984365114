.box-blue{
  width: 100%;
  height: 79px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.09);
  background-color: #fff;
  position: relative;
  z-index: 9999999;
  .box-blue-two{
    width: 100%;
    height: 79px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.09);
    background-color: #022f5d;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0;
    animation-name: bottom-animation;
    -webkit-animation-name: bottom-animation;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    transform-origin: 0% 100%;
    p {
    color: #ffffff;
    font-family: 'Arial MT';
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 1.5px;
  }
  }
}
@media(max-width: 1400px){
  .box-blue, .box-blue .box-blue-two{
    p {
      font-size:16px;}
  }
  .box-blue .box-blue-two{
    height: 50px;
  }
  .box-blue {
    height: 50px;
  }
}
@media(max-width: 722px){
  .box-blue .box-blue-two p{
    font-size: 15px;
    line-height: 17.77px;
    letter-spacing: 1.1px;
  }
}
@media(max-width: 347px){
  .box-blue .box-blue-two p{

    text-align: center;
  }
}
@keyframes bottom-animation {
  0% {
    transform: scaleX(0.0);
    top: -400px;
  }
  60% {
    transform: scaleX(1);
    top: -400px;
  }
  100% {
    transform: scaleX(1);
  }
  80% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
    top: 0;
    position: relative;
  }
}
@media (max-width: 1258px) {
  @keyframes bottom-animation {
    0% {
      transform: scaleX(0.0);
      top: -280px;
    }
    60% {
      transform: scaleX(1);
      top: -280px;
    }
    100% {
      transform: scaleX(1);
    }
    80% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(1);
      top: 0;
      position: relative;
    }
  }
}
