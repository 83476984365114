header {
  padding: 0px 0 0px;
  position: fixed;
  top: 0;
  z-index: 999999999999;
  width: 100%;
  animation-name: header-animation;
  -webkit-animation-name: header-animation;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  transform-origin: 0% 100%;
  .header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    a {
      text-decoration: none;
    }
    .logotype {
      flex-basis: 129px;
      text-decoration: none;
      img {
        display: block;
        max-width: 100%;
      }
      .logo-white{
        display: block;
      }
      .logo-blue{
        display: none;
      }
    }
    .box-header__langMenu{
      flex-basis: calc(100% - (153px + 129px));
      display: flex;
      justify-content: flex-end;
      background: #022f5d;
      align-items: center;
      flex-wrap: wrap;
      .menu{
        ul {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-wrap: wrap;
          padding: 0;
          margin: 0;
          li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-right: 75px;
            position: relative;

            .pod-menu{
              display: none;
              position: absolute;
              top: 100%;
              background: #022f5d;
              width: 850px;
              left: -40%;
              padding: 40px 20px 0;
              .pod__menu--flex{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-bottom: 20px;
                .pod__menu--left, .pod__menu--right{
                  flex-basis: 44%;
                  a {
                    margin-bottom: 20px;
                    display: block;
                  }
                }
              }
              .pod__menu--two {
                width: 100%;
                a {
                  display: block;
                  margin-bottom: 20px;
                }
              }
            }

            a {
              font-family: 'Verdana Regular';
              font-size: 20px;
              font-weight: 300;
              color: #fff;
              letter-spacing: 1.7px;
              position: relative;
              &::before {
                content: '';
                height: 2px;
                background: #fff;
                border-radius: 25px;
                position: absolute;
                bottom: -4px;
                left: 0;
                right: 0;
                width: 100%;
                display: none;
              }
              &:hover {
                &::before{
                  display: block;
                  animation: beforeHover .3s forwards;
                }
              }
            }
          }
          .active-hover{
          }
        }
      }
      .lang{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        .link-lang{
          color: #fff;
          font-family: 'Lato', sans-serif;
          font-size: 18px;
          font-weight: 300;
          letter-spacing: 1.7px;
          flex-basis: 1%;
          margin-right: 10px;
          transition-duration: 500ms;
          transition-property: font-weight;
          &:hover{
            font-weight: bold;
          }
        }
        .active-lang {
          font-weight: bold;
        }
      }
    }
    .box-hamburger{
      display: none;

    }
  }
  background: #022f5d;
}
.f-nav{
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
}
.f-nav-two{
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  background: #022f5d;

  .header-wrap {
    a {
      .logo-white{
        display: block;
      }
      .logo-blue {
        display: none;
      }
    }
    .box-header__langMenu{
      background: #022f5d;
      .menu {
        ul {
          li {
            a {
              color: #fff;
            }
          }
        }
      }
      .lang{
        .link-lang {
          color: #fff;
        }
      }
    }
    .box-hamburger{
      div {
        background: #fff;
      }
    }

  }
}
.f-content{
  padding-top: 74px;
}
@keyframes header-animation {
  0% {
    transform: scaleX(0.0);
    top: 40%;
  }
  60% {
    transform: scaleX(1);
    top: 40%;
  }
  100% {
    transform: scaleX(1);
  }
  80% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
    top: 0;
  }
}

@media(max-width: 1400px){
  header {
    .header-wrap {
      .box-header__langMenu {
        justify-content: space-between;
        .menu {
          ul {
            li {
              margin-right: 40px;
              a {

              }
            }
          }
        }
      }
    }
  }
  header .header-wrap .logotype img{
    max-width: 72%;
  }
  header .header-wrap .box-header__langMenu .menu{
    margin-right: 0;
  }
  header .header-wrap .box-header__langMenu .menu ul li a{
    font-size: 16px;
  }
  header .header-wrap .box-header__langMenu .menu ul li .pod-menu{
    width: 686px;

  }
  header .header-wrap .box-header__langMenu .menu ul li .pod-menu .pod__menu--flex .pod__menu--right{
    flex-basis: 31%;
  }
}
@media(max-width: 1200px){
  header .header-wrap .box-header__langMenu .menu ul li{
    margin-right: 10px;
  }
}
@media(max-width: 1190px){
  header {
    .header-wrap {
      .box-header__langMenu {
        flex-basis: calc(100% - (153px + 20px));
        .menu {
          margin-right: 0;
          ul {
            li {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}
@media(max-width: 1035px){
  header {
    .header-wrap {
      .box-header__langMenu {
        display: none;
        flex-basis: 100%;
        width: 100%;
        left: 0;
        right: 0;
        top: 100%;
        position: absolute;
        background: #022f5d;
        z-index: 99999999999;
        border-top: 1px solid #f1f1f1;
        .menu {
          margin-right: 0;
          width: 99%;
          flex-basis: 100%;
          ul {
            display: block;
            height: 33vh;
            overflow: auto;
            margin-top: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid #f1f1f1;
            li {
              margin-right: 20px;
              .pod-menu {
                display: block;
                position: relative;
                width: 100%;
                left: 0;
                padding: 0;
                .pod__menu--flex{
                  display: block;
                }
              }
              a {
                display: block;
                padding: 20px 0 20px 40px;
              }
            }
          }
        }
        .lang{
          width: 100%;
          display: block;
          .link-lang{
            display: block;
            padding: 20px 0 20px 40px;
          }
        }
      }
      .box-hamburger{
        display: block;
        width: 30px;
        height: 24px;
        position: relative;
        div {
          width: 100%;
          height: 4px;
          background: #fff;
          border-radius: 25px;
          margin-bottom: 4px;
        }
        .threeWhite{
          margin-bottom: 0;
        }
      }
      .open-menu {
        div {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
        .oneWhite {
          animation: openMenuDivOne .3s forwards;
        }
        .twoWhite {
          animation: openMenuDivTwo .3s forwards;
        }
        .threeWhite {
          display: none;
        }
      }
    }
  }
}
@keyframes beforeHover {
  0%{
    width: 0;
  }
  100%{
    width: 100%;
  }
}
@keyframes openMenuDivOne {
  0%{
    transform: rotate(0);

  }
  100%{
    transform: rotate(45deg);
  }
}
@keyframes openMenuDivTwo {
  0%{
    transform: rotate(0);

  }
  100%{
    transform: rotate(-45deg);
  }
}

@media(max-width: 420px){
  header .header-wrap{
    padding: 0 20px;
  }
}
