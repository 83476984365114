.our-advantages{
  background: #fff;
  position: relative;

  .title-h2 {
    margin: 0 0 60px;
    padding-top: 90px;

  }
  .our-advantages-wrap{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .advantages--element{
      flex-basis: 45%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-bottom: 30px;
      .advantages__element--icon{
        flex-basis: 15%;
        margin-right: 30px;
        img{
          display: block;
          max-width: 100%;
        }
      }
      .advantages__element--right{
        flex-basis: calc(85% - 30px);
        .title-advantages{
          color: #022f5d;
          font-family: "Bebas Neue";
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 2.83px;
          height: 50px;
        }
        .desk-advantages{
          color: #222324;
          font-family: 'Verdana Regular';
          font-size: 18px;
          font-weight: 400;

          margin: 0 0 18px;
        }
      }
    }

  }
}
@media(max-width: 1400px){
  .our-advantages .title-h2{
    padding-top: 40px;
    margin:  0 0 30px;
    font-size: 24px;
  }
  .our-advantages .our-advantages-wrap .advantages--element .advantages__element--right .title-advantages{
    font-size: 18px;
  }
  .our-advantages .our-advantages-wrap .advantages--element .advantages__element--right .desk-advantages{
    font-size: 16px;
  }


}
@media(max-width: 1180px){
  .our-advantages .our-advantages-wrap .advantages--element{
    flex-basis: 100%;
  }
  .our-advantages .our-advantages-wrap .advantages--element .advantages__element--right .title-advantages{
    height: auto;
    margin-bottom: 20px;
  }
}
@media(max-width: 465px){
  .our-advantages .our-advantages-wrap .advantages--element .advantages__element--icon{
    flex-basis: 100%;
    margin-right: 0;
    img {
      margin: 0 auto 20px;
    }
  }
  .our-advantages .our-advantages-wrap .advantages--element .advantages__element--right{
    flex-basis: 100%;
    text-align: center;
  }
}
