.white-text{
  background: #fff;
  padding: 10px 0;
  .text-p{
    color: #636363;
    font-family: 'Arial MT';
    font-size: 18px;
    font-weight: 400;
    line-height: 30.22px;
    text-align: center;
  }
}
@media(max-width: 500px){
  .white-text .text-p{
    font-size: 12px;
    line-height: 12.58px;
  }
}