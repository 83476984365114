.grand-first__screen-aw{
  background-size: 100% 100%;
  background-position: 0 0;
  height: calc(100vh - (74px + 79px));
  z-index: 9999;
  position: relative;
  .grand__first--text{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .grand-text{
      display: block;
      margin-left: 69px;
      width: 660px;
      margin-top: 7%;
      position: relative;
      padding-right: 40px;
      &::before{
        content: '';
        width: 100%;
        height: 20px;
        background: #cccccc;
        position: absolute;
        top:0;
        right: 0;
        opacity: 0;
        animation: beforeWidth .5s forwards;
        animation-delay: .5s;

      }
      &::after{
        content: '';
        height: 10px;
        width: 20px;
        background: #cccccc;
        position: absolute;
        top:20px;
        bottom: 0;
        left:0;
        opacity: 0;
        animation: afterHeight .5s forwards;
        animation-delay: 1s;
      }
      .bottom{
        display: block;
        width: 100%;
        height: 20px;
        background: #cccccc;
        position: absolute;
        bottom: calc(-10% + -40px);
        left: 0;
        opacity: 0;
        animation: beforeWidth .5s forwards;
        animation-delay: 1.5s;
      }
      .title{
        color: #022f5d;
        display: block;
        font-size: 37px;
        line-height: 38.64px;
        padding-top: 78px;
        text-align: right;
        span {
          font-size: 52px;
          line-height: 38.64px;
        }
      }
      .text-description{
        color: #022f5d;

        font-family: 'Arial MT';
        font-size: 24px;
        font-weight: 400;
        line-height: 30.22px;
        margin-bottom: 30px;
        text-align: right;
      }
    }
    .box-btn{
      flex-basis: 100%;
      position: relative;

      a{
        margin-left: auto;
        margin-right: 0;
        margin-top: 20px;
        width: 229px;
        height: 50px;
        background-color: #022f5d;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #ffffff;
        font-size: 27px;
        line-height: 27px;
        font-weight: normal;
        letter-spacing: 2.55px;
        transition-duration:  500ms;
        transition-property: background-color;
        &:hover{
          background-color: darken(#022f5d, 15%);
        }
      }
    }
  }

}
@media(max-width: 1362px){
  .grand-first__screen-aw .grand__first--text .before{
    width: 80%;
  }
}
@media(max-width: 1400px){
  .grand-first__screen-aw .grand__first--text .box-btn a{
    font-size: 22px;
  }
  .grand-first__screen-aw {
    height: calc(100vh - (50.84px + 50px));

  }
  .grand-first__screen-aw .grand__first--text .grand-text {
    padding-top: 0;
    margin-bottom: 0;
  }
  .grand-first__screen-aw .grand__first--text .grand-text{
    margin-top: 2%;
  }

}
@media(max-width: 1089px){
  .grand-first__screen-aw .grand__first--text .before{
    width: 90%;
  }
}
@media(max-width: 969px){
  .grand-first__screen-aw .grand__first--text .before{
    width: 100%;
    clip-path: polygon(4% 0, 100% 0%, 100% 100%, 0% 100%);
  }

}
@media(max-width: 763px){
  .grand-first__screen-aw .grand__first--text .before{
    width: 100%;
    clip-path: polygon(0% 0, 100% 0%, 100% 100%, 0% 100%);
  }
  .grand-first__screen-aw .grand__first--text{
    justify-content: center;
  }
  .grand-first__screen-aw .grand__first--text .grand-text .title{
    text-align: center;
    font-size: 32px;
    span {
      font-size: 44px;
    }
  }
  .grand-first__screen-aw .grand__first--text .grand-text .text-description{
    text-align: center;
    font-size: 20px;
    line-height: 24px;
  }
  .grand-first__screen-aw{
    height: auto;
  }
  .grand-first__screen-aw .grand__first--text .grand-text{
    font-size: 16px;
    text-align: center;
    margin-right: 0;
    padding-right: 0;
    &::before{
      display: none;
    }
    &::after{
      display: none;
    }
    .bottom {
      display: none;
    }
  }
  .grand-first__screen-aw .grand__first--text .box-btn a{
    margin: 0 auto;
  }
}
@media(max-width: 582px){
  .grand-first__screen-aw .grand__first--text .grand-text .title{
    padding-top: 0;
  }
  .grand-first__screen-aw .grand__first--text .grand-text{
    padding-top: 0;
  }
}
@media(max-width: 414px){
  .grand-first__screen-aw .grand__first--text .box-btn a{
    font-size: 16px;
    width: 200px;
    height: 50px;
  }
  .grand-first__screen-aw .grand__first--text .grand-text .title{
    text-align: center;
    font-size: 24px;
    span {
      font-size: 28px;
    }
  }
  .grand-first__screen-aw .grand__first--text .grand-text .text-description{
    text-align: center;
    font-size: 14px;
    line-height: 16px;
  }
}


