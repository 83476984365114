.product-production{
  margin: 50px 0;
  .wrap-production{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .box__production--left{
      flex-basis: 60%;
      position: relative;
      img{
        display: block;
        max-width: 100%;
        position: relative;
      }
      &::before{
        content: '';
        width: 50%;
        height: 20%;
        background: #022f5d;
        position: absolute;
        top: -30px;
        left: -30px;
      }

    }
    .box__production--right{
      flex-basis: 40%;
      .big-title{
        color: #022f5d;
        font-family: "Bebas Neue";
        font-size: 30px;
        font-weight: 700;
        letter-spacing: 2.83px;
        margin-bottom: 30px;
      }
      .production__list{
        color: #222324;
        font-family: 'Arial MT';

        font-size: 18px;
        font-weight: 300;
        letter-spacing: 1.7px;
        margin: 0 0 18px;
      }
    }
  }
}
@media(max-width: 1258px){
  .product-production .wrap-production .box__production--left{
    flex-basis: 30%;
  }
  .product-production .wrap-production .box__production--right{
    flex-basis: 65%;
  }
}
@media(max-width: 800px){
  .product-production .wrap-production .box__production--left{
    flex-basis: 100%;
  }
  .product-production .wrap-production .box__production--right{
    flex-basis: 100%;
  }
  .product-production .wrap-production .box__production--left img{
    margin: 0 auto 30px;
  }
}
@media(max-width: 414px){
  .product-production .wrap-production .box__production--right .big-title{
    margin-top: 20px;
    font-size: 18px;
    line-height: 18px;
  }
  .product-production .wrap-production .box__production--right .production__list{
    font-size: 15px;
    line-height: 15px;
  }
}