.bg {
  height: 425px;
  background: 0 0 no-repeat;
  background-size: cover;
  position: relative;
  .link-one{
    width: 371px;
    height: 191px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-family: 'Verdana Regular';


    font-size: 24px;
    font-weight: 700;
    text-decoration: none;
    letter-spacing: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transition-duration: 500ms;
    transition-property: transform;
    &:hover{
      transform: scale(1.1, 1);
    }
    &::before {
      width: 100%;
      content: '';
      height: 10px;
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      top: 0;
      left: 0;
    }
    &::after {
      height: calc(100% - 10px);
      content: '';
      width: 10px;
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      top: 10px;
      right: 0;
    }
    .bottom-line{
      display: block;
      &::before{
        width: calc(100% - 10px);
        content: '';
        height: 10px;
        background-color: rgba(255, 255, 255, 0.5);

        position: absolute;
        bottom: 0;
        right: 10px;
      }
    }
    &:nth-child(2) {
      left: auto;
      right: 30px;
      bottom: auto;
      top: 30px;

    }
  }
}
@media(max-width: 824px){
  .bg .link-one{
    height: 105px;
    width: 300px;
    font-size: 16px;
  }
}
@media(max-width: 362px){
  .bg .link-one:nth-child(2){
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    bottom: auto;
    margin: auto;
  }
}