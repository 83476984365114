footer {
  background-color: #022f5d;
  position: relative;
  bottom: 0;
  left: 0;
  z-index: 999;
  right: 0;
  .footer-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logotype-footer {
      flex-basis: 129px;
      text-decoration: none;
      img {
        display: block;
        max-width: 100%;
      }
    }
    .text-copy{
      color: #ffffff;
      font-family: 'Arial MT';
      font-size: 19px;
      font-weight: 400;
      line-height: 18.84px;
      letter-spacing: 1.53px;
    }
    .box-right{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;

      .link-mail{
        color: #ffffff;
        font-family: 'Arial MT';
        font-size: 19px;

        font-weight: 400;
        line-height: 18.84px;
        letter-spacing: 1.53px;
        margin: 0 100px 0 10px;
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
      .box-icon{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        a {
          text-decoration: none;
          margin-right: 10px;
          transition-duration: 500ms;
          transition-property: transform;
          &:hover{
            transform: scale(1.2) rotate(-45deg);
          }
          img {
            display: block;
            max-width: 100%;
          }
        }
      }
    }
  }
}
@media(max-width: 1400px){
  footer {
    padding: 1px 0 1px;
  }
  footer .footer-wrap .text-copy{
    font-size: 16px;
  }
  footer .footer-wrap .box-right .link-mail{
    font-size: 16px;
  }
  footer .footer-wrap .logotype-footer img {
    max-width: 72%;
  }
}
@media(max-width: 818px){
  footer {
    height: auto;
  }
  footer .footer-wrap{
    flex-wrap: wrap;
  }
  footer .footer-wrap .logotype-footer{
    flex-basis: 100%;
    height: auto;
    img {
      margin: 0 auto 20px;
      max-width: 30%;
    }
  }
  footer .footer-wrap .text-copy{
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  footer .footer-wrap .box-right{
    flex-basis: 100%;
    justify-content: space-between;
  }
  footer .footer-wrap .box-right .link-mail{
    margin: 0 ;
  }
}
@media(max-width: 414px){
  footer .footer-wrap .logotype-footer{
    flex-basis: 100%;
    height: auto;
    img {
      margin: 0 auto 20px;
      max-width: 60%;
    }
  }

}
@media(max-width: 333px){
  footer .footer-wrap .box-right .link-mail{
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  footer .footer-wrap .box-right .box-icon{
    justify-content: center;
    flex-basis: 100%;
  }
}