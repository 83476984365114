.tree{
  padding: 40px 0 60px;
  position: relative;
  z-index: 9999;
  background: #fff;
  .tree-wrap{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    .tree-left{
      flex-basis: 60%;
      position: relative;
      padding: 80px 0 80px;
      &:hover{
        img {
          transform: scale(1.2);
        }
      }
      &::before {
        width: 47.3%;
        content: '';
        height: 95%;
        background-color: #022f5d;
        position: absolute;
        top: -31px;
        left: -35px;
      }
      .box-img{
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
          img {
            width: 100%;
            height: 100%;
            transition-duration: 500ms;
            transition-property: transform;
        }
      }
      .btn-one{
        color: #ffffff;
        font-family: 'Verdana Regular';

        font-size: 16px;
        text-align: center;
        font-weight: 700;
        letter-spacing: 0;
        text-decoration: none;
        padding: 23px 56px 24px 49px;
        background-color: rgba(2, 47, 93, 0.51);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(224px - (56px + 49px));
        height: calc(114px - (23px + 24px));
        margin-left: auto;
        margin-right: 38px;
        margin-bottom: 9px;
        cursor: pointer;
        transition-duration: 500ms;
        transition-property: transform;
        &:hover{
          transform: scale(1.1, 1);
        }
        &::before {
          width: 100%;
          content: '';
          height: 10px;
          background-color: rgba(2, 47, 93, 0.5);
          position: absolute;
          top: 0;
          left: 0;
        }
        &::after {
          height: calc(100% - 10px);
          content: '';
          width: 10px;
          background-color: rgba(2, 47, 93, 0.5);
          position: absolute;
          top: 10px;
          right: 0;
        }
        .bottom-line{
          display: block;
          &::before{
            width: calc(100% - 10px);
            content: '';
            height: 10px;
            background-color: rgba(2, 47, 93, 0.5);
            position: absolute;
            bottom: 0;
            right: 10px;
          }
        }

      }

    }
    .tree-right{
      flex-basis: 35.3%;
      .three-text{
        display: block;
        margin-bottom: 30px;
        color: #222324;
        font-family: 'Verdana Regular';
        font-size: 18px;
        font-weight: 400;
        span {
          font-weight: 700;
          color: #022f5d;
        }
      }
      .box-icon{
        margin-top: 23px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .icon-element{
          flex-basis: 30%;
          .icon{
            margin: 0 auto 33px;
            width: 108px;
            height: 108px;
            border: 1px solid #022f5d;
            background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            img {
              display: block;
              max-width: 100%;
            }
          }
          .text-icon{
            color: #222324;
            font-family: 'Verdana Regular';


            font-size: 14px;
            min-height: 100px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: center;

          }
        }

      }

    }
  }
}
.tree-two{
  padding: 40px 0 100px;

  .tree-wrap {
    .tree-left{
      order: 2;
      &::before{
        width: 30%;
        height: 60%;
        left: auto;
        right: -40px;
        bottom: -40px;
        top: auto;
      }

      .btn-one{
        margin-right: auto;
        margin-left: 19px;
        margin-bottom: 81px!important;
        background-color: rgba(2, 47, 93, 0.51);


      }
    }
  }
}
.tree-four {
  padding: 40px 0 100px;

  .tree-wrap {
    .tree-left{
      padding: 185px 0 181px!important;
      &::before{
        top: auto;
        bottom: -40px;
      }

    }
  }

}
.tree-five
{
  padding: 80px 0 60px;

  .tree-wrap {
    .tree-left{
      flex-basis: 45%;
      order: 2;
      padding: 154px 0 74px!important;
      &::before{
        width: 70%;
        height: 30%;
        left: auto;
        right: 0;
        top: -40px;
        bottom: auto;
      }
      .box-img {
        width: 95%;
      img {
        max-width: 100%;
        width: auto;
        height: auto;
        margin-left: 0;
      }
      }
      .btn-one{

        margin-right: auto;
        margin-left: 19px;
        margin-bottom: 81px!important;
        &::before{

        }
        &::after{
          right: auto;
          left: 0;
        }
        .bottom-line {
          &::before{

            right: auto;
            left: 10px;
          }
        }
        &:nth-child(2){
          margin-bottom: 0;
          &::before{

          }
          &::after{
            right: auto;
            left: 0;
          }
          .bottom-line {
            &::before{

              right: auto;
              left: 10px;
            }
          }
        }
      }
    }
    .tree-right {
      flex-basis: 51%;
      .overflow-auto{
        max-height: 600px;
        overflow: auto;
        padding-right: 20px;
        &::-webkit-scrollbar-thumb {

          background-color: rgba(2, 47, 93, 0.8);
          border-radius: 25px;

        }

        &::-webkit-scrollbar-thumb:hover{
          background-color:#56999f;
          border-radius: 25px;

        }

        &::-webkit-resizer{
          background-repeat:no-repeat;
          width:5px;
          height: 10px;
          border-radius: 25px;

        }

        &::-webkit-scrollbar{
          width: 6px;
          height: 10px;
          border-radius: 25px;
        }
      .three-text{
        a {
          text-decoration: none;
          color: red;
          &:hover{
            text-decoration: underline;
          }
        }
      }
      }
    }

  }

}
.tree-six{
  margin-top: -10%;
  .tree-wrap {
    .tree-left{
      flex-basis: 45%;
      padding: 274px 0 204px!important;
      order: 2;

      .box-img {
        height: 600px;
        width: 87%;
        img {
          width: 100%;
          height: 100% !important;
        }
      }
      &::before{
        width: 70%;
        height: 30%;
        left: auto;
        right: 40px;
        top: auto;
        bottom: 20px;
      }

    }
    .tree-right {
      .technical-title{
        text-align: right;
      }
      .three-text {
        text-align: right;
      }
    }


  }

}
.tree-seven{
 .tree-wrap {
   align-items: flex-start;
   .tree-left {
     flex-basis: 45%;
     padding: 140px 0!important;
   }
   .tree-right {
     flex-basis: 50%;

   }
 }
}
@media(max-width: 1400px){
  .tree .tree-wrap .tree-left{
    padding: 50px 0 40px;
  }
  .tree{
    margin-top: -40px;
    padding: 81px 0 50px;
  }

  .tree .tree-wrap .tree-right .three-text{
    font-size: 16px;
  }
  .tree .tree-wrap .tree-left::before{
    width: 21.3%;
  }
  .tree .tree-wrap .tree-left .btn-one{
    padding: 10px 36px 14px 29px;
    font-size: 14px;
  }

  // tree-two
  .tree-two .tree-wrap .tree-left{
    padding: 80px 0 40px;

  }
  .tree-two .tree-wrap .tree-left .btn-one{
    margin-bottom: 40px!important;
  }
  .tree-two{
    margin-bottom: -30px;
  }
  //tree-four
  .tree-four .tree-wrap .tree-left{
    padding: 160px 0 156px!important;

  }
  .tree-four {
    margin-bottom: -60px;

  }


  .tree .tree-wrap .tree-right .three-text{
    margin-bottom: 20px;
  }
  .technical-title{
  }
  .tree-five {
    padding: 81px 0 50px;
  }
  .tree-five .tree-wrap .tree-left{
    flex-basis: 37%;
    padding: 80px 0 20px!important;
  }
  .tree-five .tree-wrap .tree-right{
    flex-basis: 62%;
  }
  .tree-five .tree-wrap .tree-left .btn-one{
    margin-bottom: 20px;

  }
  .tree-five .tree-wrap .tree-right .overflow-auto{
    max-height: 350px;
  }
  //six
  .tree-six{
    padding: 91px 0 50px;
  }
  .tree-six .tree-wrap .tree-left {
    padding: 141px 0 141px!important;
    flex-basis: 45%;
  }
  .tree-six .tree-wrap .tree-left::before {
    display: none;
  }
  .tree-six{
    margin-top: -70px;
  }
  .tree-six .tree-wrap .tree-right{
    flex-basis: 50%;
  }
  .tree-six .tree-wrap .tree-left .box-img{
    height: auto;

  }
  //seven
  .tree-seven{
    margin-bottom: 0;
    padding: 81px 0 100px;
  }
}
@media(max-width: 1012px){
  .tree-six .tree-wrap .tree-left .box-img{
    height: auto;
    width: 100%;
  }
  .tree-five .tree-wrap .tree-left{
    order: 0;
    margin: 0 auto 40px;
  }
  .tree-two .tree-wrap .tree-left{
    order: 0;
    margin: 40px auto;
  }
  .tree .tree-wrap .tree-left{
    flex-basis: 70%;
    margin: 0 auto 50px;
    img {
      max-width: 100%;
      width: auto;
      height: auto;
      display: block;
    }
  }
  .tree .tree-wrap .tree-right{
    flex-basis: 100%;
  }
}
@media(max-width: 834px){
  .tree .tree-wrap .tree-left .btn-one{
    width: calc(200px - (20px + 20px));
    height: calc(80px - (12px + 12px));
    padding: 12px 20px 21px 20px;
    font-size: 14px;
  }
  .tree .tree-wrap .tree-left::before{
    width: 32.3%;
    top: -50px;
  }
}
@media(max-width: 781px){
  .tree-six .tree-wrap .tree-left{
    padding: 154px 0 74px!important;
  }
  .tree-five .tree-wrap .tree-left::before{
    display: none;
  }
  .tree-four{
    padding: 40px 0 0;
  }
}
@media(max-width: 689px){
  .tree .tree-wrap .tree-left{
    flex-basis: 100%;
  }
  .tree-five .tree-wrap .tree-left .box-img{
    width: 100%;
  }
  .tree .tree-wrap .tree-left::before {
    width: 32.3%;
    top: -89px;
  }
  .tree{
    padding: 50px 0 30px;
  }
  .tree .tree-wrap .tree-right .box-icon .icon-element .icon{
    width: 49px;
    height: 49px;
    img {
      max-width: 40%;
    }
  }
  .tree .tree-wrap .tree-right .box-icon .icon-element .text-icon{
    font-size: 8px;
    line-height: 10.68px;
    letter-spacing: 1.82px;
    min-height: 40px;
  }
  .tree-five .tree-wrap .tree-right .box-icon .icon-element .icon{
    width: 49px;
    height: 49px;
    img {
      max-width: 40%;
    }
  }
  .tree-five .tree-wrap .tree-right .box-icon .icon-element .text-icon{
    font-size: 8px;
    line-height: 10.68px;
    letter-spacing: 1.82px;
    min-height: 40px;
  }
  .tree .tree-wrap .tree-left::before{
    display: none;
  }
}
@media(max-width: 498px){
  .tree .tree-wrap .tree-left::before{
    display: none;
  }
  .tree .tree-wrap .tree-left .btn-one{
    font-size: 10px;
    width: calc(150px);
    height: calc(40px);
  }
  .tree-five .tree-wrap .tree-left, .tree-two .tree-wrap .tree-left{
    margin: 0 auto!important;
  }
  .tree-six .tree-wrap .tree-left .box-img img{
    height: auto!important;
  }
}

