.box-grand-text{
  padding-bottom: 50px;
  background: #fff;
  padding-top: 70px;
  position: relative;
  z-index: 9999999;

  .title-grand {
    font-size: 30px;
    margin-bottom: 30px;
    font-weight: bold;
  }
  .desk-advantages{
    margin-bottom: 50px;
    color: #222324;
    font-family: 'Verdana Regular';
    font-size: 18px;
    font-weight: 400;
  }
}
.box-white{
  height: calc(100vh - (74px));

}
@media(max-width: 1400px){
  .box-grand-text{
    padding-top: 30px;
  }
  .box-grand-text .title-grand, .technical-character .technical-character-wrap .technical-left .title-grand{
    margin-bottom: 20px;
    font-size: 24px;
  }
  .box-grand-text .desk-advantages{
    font-size: 16px;
    margin-bottom: 30px;
    line-height: 20px;
  }
  .box-white{
    height: calc(100vh - (53px));

  }
}
@media(max-width: 1000px){
  .box-grand-text {
    padding-top: 20px;
    .title-grand {
      font-size: 24px;

    }
    .desk-advantages {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }
}

