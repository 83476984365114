.slider-a4{
  background: #fff;
  position: relative;
  z-index: 99999;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px 0;
  margin-top: -1px;
  .slide-a4{
    flex-basis: 28%;
    margin-right: 30px;
    img {
      display: block;
      max-width: 100%;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
