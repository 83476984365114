.contact{
  height: calc(100vh - (74px + 79px));
  position: relative;

  .contact-wrap{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .contact-left{
      flex-basis: 60%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &::before {
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        left: 40px;
        right: 0;
        margin: auto;
        width: 1px;
        background: #dddddd;
      }
      .contact-left__left{
        flex-basis: 49%;
        .title-h2 {
          margin-bottom: 30px;
          text-align: left;
          font-size: 24px;

        }
        .text-p {
          display: block;
          text-decoration: none;
          margin-bottom: 18px;
          letter-spacing: 0;
          font-family: 'Verdana Regular';

        }
        a {
          margin-bottom: 10px!important;
          transition-duration: 500ms;
          font-family: 'Verdana Regular';

          transition-property: color;
          &:hover{
            color: #022f5d;
          }
        }
      }
      .contact-maps {
        flex-basis: 80%;
        margin-top: 40px;
        height: 300px;

      }
    }
    .contact-right{
      flex-basis: 38%;
      .title-h2{
        font-size: 24px;
      }
      form {
        input {
          display: block;
          width: 100%;
          height: 50px;
          padding-left: 15px;
          font-family: 'Verdana Regular';
          margin-bottom: 10px;
          border: 1px solid #dddddd;
          outline: none;
          box-shadow: none;
          &:focus{
            color: #022f5d;
            border-color: #022f5d;
          }
        }
        textarea {
          resize: none;
          width: 100%;
          height: 100px;
          padding-left: 15px;
          padding-top: 10px;
          font-family: 'Verdana Regular';

          outline: none;
          border: 1px solid #dddddd;
          box-shadow: none;
          &:focus{
            color: #022f5d;
            border-color: #022f5d;
          }
        }
        .box-input{
          .file {
            outline: none;
            position: absolute;
            top: -99999999px;

          }
          label {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 20px 0;
            font-size: 16px;
            font-family: 'Lato', sans-serif;
            color: #c1c1c1;
            transition-duration: 500ms;
            transition-property: color;
            &:hover{
              cursor: pointer;
              color: #022f5d;
              svg {
                fill: #022f5d;
              }
            }
            svg {
              width: 30px;
              height: 40px;
              fill: #c1c1c1;
              margin-right: 20px;
              transition-duration: 500ms;
              transition-property: fill;
            }
          }
        }
        button {
          border: none;
          box-shadow: none;
          width: 100%;
          height: 60px;
          font-family: 'Verdana Regular';

          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #022f5d;
          color: #fff;
          transition-duration: 500ms;
          transition-property: background-color;
          cursor: pointer;
          line-height: 0;
          &:hover{
            background-color: darken(#022f5d, 15%);
          }

        }
      }
    }
  }
}
@media(max-width: 1400px){
  .contact{
    height: calc(100vh - (100px));

    .contact-wrap {
      align-items: flex-start;


      .contact-left {
        .contact-left__left {
          .title-h2 {
            font-size: 24px;
            margin-bottom: 10px;
          }
          .text-p {
            margin-bottom: 10px;
            font-size: 16px;
          }
          a {
            font-size: 16px;
          }
        }
        .contact-maps {
          height: 200px;
          margin-top: 20px;
        }
      }
      .contact-right {
        form {
          input {
            height: 30px;
            width: calc(100% - 15px);
            font-size: 16px;
          }
          textarea {
            height: 50px;
            width: calc(100% - 15px);
            font-size: 16px;
          }
          .box-input {
            margin: 5px 0;
            label {
              margin: 0;
            }
          }
          button{
            font-size: 22px;
            height: 50px;
          }
        }
      }
    }
  }
}
@media(max-width: 1000px){
  .contact {
    height: auto;
    .contact-wrap {
      .contact-right {
        flex-basis: 100%;
        margin-bottom: 20px;
      }
      .contact-left {
        flex-basis: 100%;
        &::before {
          display: none;
        }
      }
    }
  }
}