.price-one{
  position:relative; top: 0;
  height: calc(100vh - (74.8px + 79px));
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 80%;
    height: 80%;
    margin: 0 auto;
    display: block;
  }
}
@media(max-width: 1400px){
  .price-one {
    height: calc(100vh - (100px));
  }
}
@media(max-width: 908px){
  .price-one {
    height: auto;
  }
}