.wood-profile{
  .wood-wrap{
    height: calc(100vh - (74.8px + 79px));
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .wood__left {
      flex-basis: 55%;
      img {
        display: block;
        width: 100%;
        margin: 0 auto;
      }
    }
    .wood-right {
      flex-basis: 45%;
      .box-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 50px;
        &:last-child{
          margin-bottom: 0;
        }
        .wood-link{
          color: #ffffff;
          font-family: 'Verdana Regular';
          font-size: 16px;
          text-align: center;
          font-weight: 700;
          letter-spacing: 0;
          text-decoration: none;
          padding: 23px 56px 24px 49px;
          background-color: rgba(2, 47, 93, 0.81);
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(224px - (56px + 49px));
          height: calc(114px - (23px + 24px));
          margin-left: auto;
          margin-right: 38px;
          margin-bottom: 9px;
          cursor: pointer;
          transition-duration: 500ms;
          transition-property: transform;
          &:hover{
            transform: scale(1.1, 1);
          }
          &::before {
            width: 100%;
            content: '';
            height: 10px;
            background-color: rgba(2, 47, 93, 0.5);
            position: absolute;
            top: 0;
            left: 0;
          }
          &::after {
            height: calc(100% - 10px);
            content: '';
            width: 10px;
            background-color: rgba(2, 47, 93, 0.5);
            position: absolute;
            top: 10px;
            right: 0;
          }
          .bottom-line{
            display: block;
            &::before{
              width: calc(100% - 10px);
              content: '';
              height: 10px;
              background-color: rgba(2, 47, 93, 0.5);
              position: absolute;
              bottom: 0;
              right: 10px;
            }
          }

        }

      }
    }
  }
}
@media(max-width: 1400px){
  .wood-profile .wood-wrap .wood__left{
    flex-basis: 60%;
    img {
      max-width: 80%;
    }
  }
  .wood-profile .wood-wrap .wood-right{
    flex-basis: 40%;
  }
  .wood-profile .wood-wrap .wood-right .box-link{
    margin-bottom: 0;
  }
  .wood-profile .wood-wrap .wood-right .box-link .wood-link{
    padding: 10px 30px 9px 23px;;
    font-size: 14px;
    margin-right: 0;
    &::before{
      height: 5px;
    }
    &::after{
      width: 5px;
      height: calc(100% - 5px);
      top: 5px;
    }
  }
  .wood-profile .wood-wrap .wood-right .box-link .wood-link .bottom-line::before{
    height: 5px;
    width: calc(100% - 5px);
    right: 5px;
  }
}
@media(max-width: 1194px){
  .wood-profile .wood-wrap{
    height: auto;
  }
}
@media(max-width: 796px){
  .wood-profile .wood-wrap{
    height: auto;
  }
  .wood-profile .wood-wrap .wood__left{
    flex-basis: 100%;
    margin-bottom: 20px;

  }
  .wood-profile .wood-wrap .wood-right{
    flex-basis: 100%;
  }
}