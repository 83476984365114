.logotype-two {
  background: #fff;
  position: relative;
  padding: 50px 0;
  .swiper-slide{
    .box-slide{
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        display: block;
        max-width: 100%;
      }
    }
  }
}
@media(max-width: 1400px){
  .logotype-two{
    padding: 0 0 50px ;
  }
}