.technical-character{
  position: relative;
  z-index: 9999;
  background: #fff;
    .technical-character-wrap{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      padding-bottom: 30px;
      .technical-left{
        flex-basis: 65.8%;
        padding-top: 90px;
        .title-grand {
          font-size: 30px;
          margin-bottom: 30px;
        }
        .technical-text{
          color: #222324;
          font-family: 'Verdana Regular';
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 10px;
          position: relative;
          margin-left: 20px;
          &::before{
            content: '';
            width: 10px;
            height: 10px;
            position: absolute;
            background: #ccc;
            border-radius: 50%;
            top: 0;
            bottom: 0;
            left: -20px;
            margin: auto;
          }
        }
        .box-icon{
          margin-top: 23px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          .icon-element{
            flex-basis: 30%;
            .icon{
              margin: 0 auto 33px;
              width: 108px;
              height: 108px;
              border: 1px solid #022f5d;
              background-color: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              img {
                display: block;
                max-width: 100%;
              }
            }
            .text-icon{
              color: #222324;
              font-family: 'Verdana Regular';


              font-size: 18px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing:0;
              text-align: center;

            }
          }

        }
      }
      .technical-right {
        margin-top: 40px;
        flex-basis: 32.2%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 0;
        img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
        }
      }
    }
}
.technical-title{
  color: #022f5d;
  font-family: "Bebas Neue";
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 2.83px;
  margin-bottom: 30px;
}

@media(max-width: 1400px){
  .technical-character .technical-character-wrap .technical-left .title-grand, .technical-title {
    font-size: 24px;
  }
  .technical-character .technical-character-wrap .technical-left .technical-text{
    font-size: 16px;
    &::before {
      width: 6px;
      height: 6px;
    }
  }
  .technical-character .technical-character-wrap .technical-left{
    padding-top: 50px;
  }
  .technical-character .technical-character-wrap .technical-right{
    flex-basis: 21.2%;
  }
}
@media(max-width: 809px){
  .technical-character .technical-character-wrap .technical-left{
    flex-basis: 100%;
    order: 2;
  }
  .technical-title{
    font-size: 14px;
    letter-spacing: 2.43px;

  }

  .technical-character .technical-character-wrap .technical-right{
    flex-basis: 100%;
    border-left: none;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 20px;
    img {
      max-width: 30%;
      transform: rotate(-90deg);
    }
  }
  .technical-character .technical-character-wrap .technical-left .technical-text{
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 2.43px;
    margin-bottom: 4px;

    &::before {
      width: 6px;
      height: 6px;
    }
  }
  .technical-character .technical-character-wrap .technical-right{
    flex-basis: 21.2%;
    padding-left: 25px;
  }
}
@media(max-width: 661px){
  .technical-character .technical-character-wrap .technical-left .box-icon .icon-element .text-icon{
    font-size: 8px;
    line-height: 10.68px;
    letter-spacing: 1.82px;
    height: 40px;
  }
  .technical-character .technical-character-wrap .technical-left .box-icon .icon-element .icon{
    width: 49px;
    height: 49px;
    img {
      max-width: 40%;
    }
  }
  .technical-character .technical-character-wrap .technical-right{
    img {
      max-width: 50%;
    }
  }
}