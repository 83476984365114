@mixin clearfix() {
  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin min-max($min-width, $max-width, $padding) {
  min-width: $min-width;
  max-width: $max-width;
  padding: 0 $padding;
  box-sizing: border-box;
  margin: 0 auto;
}

@mixin adaptive-video() {
  position: relative;
  padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}

@mixin visually-hidden() {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0,0,0,0);
  overflow: hidden;
}

.title-h2{
  color: #022f5d;
  font-family: "Bebas Neue";
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 2.83px;
  text-align: center;
}
.text-p{
  color: #222324;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  letter-spacing: 1.7px;
  margin: 0 0 18px;
}
.title-grand{
  color: #022f5d;
  font-family: "Bebas Neue";
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 2.83px;
}
.back{
  color: #222324;
  font-family: 'Verdana Regular';
  font-size: 18px;
  font-weight: 400;
  margin: 18px 0 18px;
  display: block;
  position: absolute;
  bottom: 0;
  right: 30px;
  z-index: 9999;
  svg {
    width: 45px;
    height: 45px;
    fill: #022f5d;
    transition-duration: 500ms;
    transition-property: fill;
    &:hover{
      fill: #000000;
    }
  }
}
.desk-advantages{
  color: #222324;
  font-family: 'Verdana Regular';
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 18px;
}


.title-product {
  color: #022f5d;
  font-family: "Bebas Neue";
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 2.83px;
  margin: 0 0 25px;
}
.span-top{
  color: #222324;
  font-family: 'Verdana Regular';
  font-size: 18px;
  font-weight: 400;
  display: block;
  margin-top: 36px;
}
.desk-product{
  color: #222324;
  font-family: 'Verdana Regular';
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 18px;
}
.btn-more{
  width: 229px;
  height: 50px;
  background-color: #022f5d;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          color: #ffffff;
          font-size: 27px;
          line-height: 27px;
          font-weight: normal;
          letter-spacing: 2.55px;
          transition-duration:  500ms;
          transition-property: background-color;
  margin-bottom: 36px;
          &:hover{
            background-color: darken(#022f5d, 15%);
          }
}
@media(max-width: 1400px){
  .btn-more{
    font-size: 22px;
  }
  .span-top {
    font-size: 16px;
  }
}
@media(max-width: 1000px){
  .title-h2{
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 30px;
  }
}
@media(max-width: 467px){
  .title-product{
    font-size: 24px;

  }
  .span-top {
    font-size: 16px;
  }
  .desk-product{
    font-size: 16px;
  }
  .btn-more{
    font-size: 16px;
    width: 200px;

  }
}
