.box-grand-text-two, .box-grand-text-three{
  position: relative;
  background: #fff;
  z-index: 99999;
  margin-top: -1px;
  .wrap-grand{
    .desk-advantages{
      font-weight: 500;
      font-size: 16px;
      font-family: 'Comfortaa', cursive;
      font-style: italic;
      margin-bottom: 0;
    }
  }
}
@media(max-width: 1400px){
  .box-grand-text-two {
    padding: 60px 0 0;

  }
  .box-grand-text-three{
    padding: 0 0 60px;
  }
}