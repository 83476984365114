.girl-first{
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(100vh - (74.8px + 79px));
  position: relative;
  overflow: hidden;
  .box-white-girl{
    width: 45%;
    background: #fff;
    padding: 30px 40px;

    position: relative;
    margin-top: 6%;
    margin-left: auto;
    margin-right: 20px;
    .title-h2{
      text-align: left;
      margin-bottom: 20px;
    }
    .box-girl-scroll{
      height: 360px;
      position: relative;
      overflow: auto;
      padding-right: 0;
      &::-webkit-scrollbar-thumb {

        background-color: rgba(2, 47, 93, 0.8);
        border-radius: 25px;

      }

      &::-webkit-scrollbar-thumb:hover{
        background-color:#56999f;
        border-radius: 25px;

      }

      &::-webkit-resizer{
        background-repeat:no-repeat;
        width:5px;
        height: 10px;
        border-radius: 25px;

      }

      &::-webkit-scrollbar{
        width: 6px;
        height: 10px;
        border-radius: 25px;
      }
      .text-list--girl{
        color: #222324;
        font-family: 'Verdana Regular';
        font-size: 18px;
        font-weight: 400;
        margin: 0 0 18px;
      }
      .text-p{
        color: #222324;
        font-family: 'Verdana Regular';
        font-size: 18px;
        font-weight: 400;
        margin: 0 0 18px;
        letter-spacing: 0;
      }
    }

  }
  .big-girl{
    position: absolute;
    bottom: 0;
    right: 10%;
    display: block;
    max-width: 25%;
  }
}
@media(max-width: 1400px){
  .girl-first{
    height: calc(100vh - (53.84px + 50px));

  }
  .girl-first .box-white-girl{
    margin-top: 4%;
  }
  .girl-first .box-white-girl .title-h2{
    font-size: 24px;
  }
  .girl-first .box-white-girl .box-girl-scroll .text-list--girl, .girl-first .box-white-girl .box-girl-scroll .text-p{
    font-size: 16px;
  }
  .girl-first .box-white-girl .box-girl-scroll .text-list--girl, .girl-first .box-white-girl .box-girl-scroll .text-p{
    margin-bottom: 10px;
  }
  .girl-first .box-white-girl{
    width: 48%;
    padding: 20px 20px;
  }
  .girl-first .box-white-girl .box-girl-scroll {
    height: 274px;
  }
}
@media(max-width: 1000px){
  .girl-first .box-white-girl .box-girl-scroll{
    height: 290px;
  }
  .girl-first .box-white-girl {
    margin-top: 5%;
  }
  .girl-first .box-white-girl .title-h2{
    margin-bottom: 10px;
  }
  .girl-first .box-white-girl .big-girl{
    top: 7%;

  }
  .girl-first .box-white-girl{
    width: 40%;
  }
  .girl-first .box-white-girl .text-p{
    font-size: 13px;
  }
  .girl-first .box-white-girl .text-list--girl{
    font-size: 12px;

    margin-bottom: 5px;
  }
  .girl-first .box-white-girl .text-title{
    margin-bottom: 0;
  }
}
@media(max-width: 1086px){
  .girl-first .box-white-girl .big-girl{
    bottom: -100px;
    max-width: 60%;
    right: -50%;

  }
  .girl-first .box-white-girl .text-title{
    font-size: 16px;
    line-height: 19.28px;
    margin-bottom: 10px;
  }
  .girl-first .box-white-girl .text-list--girl{

  }
  .girl-first {
  }
}
@media(max-width: 687px){
  .girl-first .big-girl{
    display: none;
  }
  .girl-first{
    padding: 20px 0;
  }
  .girl-first .box-white-girl{
    width: calc(90% - 60px);
    margin: 0 auto;
  }
}
@media(max-width: 500px){
  .girl-first .box-white-girl{
    width: calc(100% - 40px);
    padding: 20px;
  }
  .girl-first {
    height:auto;
    padding: 0;
  }
  .girl-first .box-white-girl{
    background: rgba(0,0,0, 0.6);
    color: #fff;
    .box-girl-scroll {
      &::-webkit-scrollbar-thumb {

        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 25px;

      }
    }
  }
  .girl-first .box-white-girl .box-girl-scroll .text-list--girl, .girl-first .box-white-girl .box-girl-scroll .text-p {
    color: #fff;
  }
  .girl-first .box-white-girl .title-h2{
    color: #fff;
  }
}
