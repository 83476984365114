.box-line-button{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  background: #fff;
  width: calc(100% - 50px);
  margin: 0 auto;
  padding: 20px 25px;
  .text-button{
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    color:#022f5d;
    font-weight: 400;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
@media(max-width: 935px){
  .box-line-button .text-button{
    font-size: 16px;
  }
}
@media(max-width: 661px){
  .box-line-button .text-button{
    font-size: 14px;
  }
}
@media(max-width: 554px){
  .box-line-button .text-button{
    font-size: 14px;
    flex-basis: 50%;
    text-align: center;
    line-height: 15px;
    padding: 10px 0;
    margin-bottom: 0;
  }
  .box-line-button{
    padding: 15px 5px;
    width: calc(100% - 10px);
  }
}

