.slider-fixed{
  height: calc(100vh - (74px));
  z-index: 999;
  left: 0;
  right: 0;
  top: 74px;
  position: fixed;
  .swiper-container{
    width: 100%;
    height: 100%;
    .swiper-wrapper{
      .swiper-slide{
        background-repeat     : no-repeat;
        background-size       : auto 100%;
        background-position-x : center;
        background-position-y : center;
      }
    }
  }
  .swiper-pagination{
    left: 0;
    right: 0;
    margin: auto;
    position: fixed;
    bottom: 30px;
    z-index: 999999999;
  }
  .swiper-pagination-bullet {
    margin-right: 10px;
    z-index: 99999999999;
    width: 35px;
    height: 7px;
    display: inline-block;
    background: #fff;
    outline: 0;
    padding: 0;
    text-decoration: none;
    border-radius: 0;
    opacity: 1;
    transition-duration: 500ms;
    transition-property: background-color;
    &:hover{
      background-color: #1b2733;
    }
  }
  .swiper-pagination-bullet-active{
    background: #1b2733;
  }

}
@media(max-width: 1400px){
  .slider-fixed {
    height: calc(100vh - (53px));
    top: 53px;

  }
}