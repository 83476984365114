.first-screen-position{
  height: calc(100vh - (74px + 79px));
}
.first-screen{
  height: calc(100vh - (74px + 79px));
  position: fixed;
  overflow: hidden;
  top: 74px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .swiper-container {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    .swiper-wrapper{
      height: 100%;
      .swiper-slide{
        background-repeat     : no-repeat;
        background-size       : 100% 100%;
        background-position-x : 0;
        background-position-y : 0;
      }
    }


  }
  .swiper-pagination{
    left: 0;
    right: 0;
    margin: auto;
    bottom: 4px;
    z-index: 999999999;
  }
  .swiper-pagination-bullet {
    margin-right: 10px;
    z-index: 99999999999;
    width: 35px;
    height: 7px;
    display: inline-block;
    background: #fff;
    outline: 0;
    padding: 0;
    text-decoration: none;
    border-radius: 0;
    opacity: 1;
    transition-duration: 500ms;
    transition-property: background-color;
    &:hover{
      background-color: #1b2733;
    }
  }
  .swiper-pagination-bullet-active{
    background: #1b2733;
  }
  .box-first--text{
    z-index: 999;
    .before{
      display: none;
      position: absolute;
      top:0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 65%;
      height: 100%;
      background-image: linear-gradient(-216deg, rgba(79, 83, 149, 0.64) 0%, rgba(33, 37, 124, 0.64) 52%, rgba(213, 186, 186, 0.64) 100%);
      clip-path: polygon(19% 0, 100% 0%, 100% 100%, 0% 100%);
      opacity: 0.5;
      animation: right-opacity .5s forwards;
    }
    .box-text__text{
      margin-right: 69px;
      width: 612px;
      position: relative;
      &::before{
        content: '';
        width: 100%;
        height: 20px;
        background: #cccccc;
        position: absolute;
        top:0;
        left:0;
        right: 0;

        opacity: 0;
        animation: beforeWidth .5s forwards;
        animation-delay: .5s;

      }
      &::after{
        content: '';
        height: 120%;
        width: 20px;
        background: #cccccc;
        position: absolute;
        top:20px;
        bottom: 0;
        right:0;
        opacity: 0;
        animation: afterHeight .5s forwards;
        animation-delay: 1s;
      }
      .bottom{
        display: block;
        width: 100%;
        height: 20px;
        background: #cccccc;
        position: absolute;
        bottom: calc(-10% + -40px);
        right: 0;
        opacity: 0;
        animation: beforeWidth .5s forwards;
        animation-delay: 1.5s;
      }
      .title{

        color: #022f5d;

        font-size: 52px;
        line-height: 38.64px;
        font-weight: 400;
        margin-bottom: 16px;
        padding-top: 78px;
        span {
          display: block;
          font-size: 37px;
          line-height: 38.64px;

        }
      }
      .text-description {
        color: #022f5d;

        font-family: 'Arial MT';
        font-size: 24px;
        font-weight: 400;
        line-height: 30.22px;
        margin-bottom: 70px;
      }
    }
  }
  .box-line-button{
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: auto;
    width: calc(100% - 80px);
    z-index: 999;
    padding: 0 40px;
    background: none;
    .text-button{
      color: #022f5d;

      font-family: 'Arial MT';
      font-size: 24px;
      font-weight: 400;
      line-height: 24.22px;
      text-transform: lowercase;
    }
  }
}
@keyframes right-opacity {
  0%{
    right: -1000px;
  }
  100%{
    right: 0;
  }
}
@keyframes beforeWidth {
  0%{
    width: 0;
    opacity: 0;
  }
  100%{
    width: 100%;
    opacity: 0.53;
  }
}
@keyframes afterHeight {
  0%{
    height: 0;
    opacity: 0;
  }
  100%{
    height: 110%;
    opacity: 0.53;
  }
}
@media (max-width: 1458px) {
  .first-screen .swiper-container .swiper-wrapper .swiper-slide img{
    height: 100%;
  }
}
@media(max-width: 1400px){
  .first-screen-position{
    height: calc(100vh - (53.84px + 50px));
  }
  .first-screen{
    height: calc(100vh - (53.84px + 50px));
    top: 50px;

  }

}

@media(max-width: 1258px){

  .first-screen .box-first--text .before{
    width: 80%;
  }
  .first-screen .box-line-button{
    bottom: 21px;
  }
  .first-screen .box-first--text .box-text__text .text-description{
    margin-bottom: 135px;
  }
  .first-screen .box-line-button .text-button{
    font-size: 18px;
    line-height: 18px;
  }
}






@media(max-width: 1161px){
  .first-screen{
    height: 318px;
  }
  .first-screen-position{
    height: 318px;

  }
  .first-screen .box-first--text .before{
    height: 100%;
    width: 100%;
    clip-path: none;
  }
  .first-screen .box-first--text{
    width: 100%;
    height: 100%;
  }
  .first-screen .box-first--text .box-text__text{
    width: 100%;
    &::before{
      display: none;
    }
    &::after{
      display: none;
    }
    .bottom{
      display: none;
    }
  }
  .first-screen .box-first--text .box-text__text .text-description{
    width: 50%;
    margin: 0 auto;
    text-align: center;
  }
  .first-screen .box-first--text .box-text__text .title{
    text-align: center;
  }
  }
@media(max-width: 1064px){
  .first-screen .box-line-button{
    display: none;
  }
}
@media(max-width: 643px){
  .first-screen .box-first--text .box-text__text .text-description{
    width: 100%;
  }
  .first-screen .box-first--text .box-text__text .title{
    font-size: 25px;
    letter-spacing: 4.08px;
    padding-top: 22px;
    line-height: 18.42px;
    margin-bottom: 9px;
    span{
      font-size: 18px;
      letter-spacing: 2.93px;
      line-height: 18.42px;


    }
  }
  .first-screen .box-first--text .box-text__text .text-description {
    font-size: 13px;
    line-height: 16.25px;
  }
  .first-screen {
    height: 150px;
  }
  .first-screen-position{
    height: 150px;

  }
  .first-screen .swiper-pagination-bullet{
    width: 20px;
  }
}