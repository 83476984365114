@charset "UTF-8";

/**
 * Основные стили
 **/

html {
	font-size: 16px;
}

body {
	font-family: 'Bebas Neue';
	overflow-wrap: break-word;
	word-wrap: break-word;
	font-weight: normal;
}

.container {
	@include min-max(320px, 100%, 0);
	position: relative;
	overflow: hidden;
	min-height: 100vh;
	padding-bottom:0;
	padding-top: 74px;
}

.wrap {
	@include min-max(320px, 1288px, 15px);
}
.wrap-two {
	@include min-max(320px, 1288px, 15px);
}
.wrap-grand {
	@include min-max(320px, 1000px, 15px);

}
.wrap-header {
	@include min-max(320px, 1288px, 15px);

}

@media(max-width: 1400px){
	.wrap-two {
		@include min-max(320px, 1130px, 15px);
	}
	.wrap {
		@include min-max(320px, 1130px, 15px);
	}
	.container {
		@include min-max(320px, 100%, 0);
		position: relative;
		overflow: hidden;
		min-height: 100vh;
		padding-bottom:0;
		padding-top: 50px;
	}
}
@media(max-width: 1000px){
	.wrap-grand {
		@include min-max(320px, 600px, 15px);

	}
}